<template>
  <v-app
    :style="
      isShowRequestForm || showNavigationDrawer
        ? 'height: 100svh; overflow:hidden'
        : ''
    "
  >
    <Header
      @showRequestForm="isShowRequestForm = true"
      @showNavigationDrawer="showNavigationDrawer = true"
    />
    <router-view @showRequestForm="showRequestForm"></router-view>
    <Footer />
    <v-expand-x-transition>
      <request-form
        v-if="isShowRequestForm"
        @close="isShowRequestForm = false"
        :productId="productId"
      />
    </v-expand-x-transition>
    <mobile-navigation-drawer
      v-if="showNavigationDrawer"
      :headerItems="headerItems"
      @close="showNavigationDrawer = false"
      @showRequestForm="
        (showNavigationDrawer = false), (isShowRequestForm = true)
      "
    />
    <transition name="fade">
      <div v-scroll="onScroll" class="toTop" v-show="top" @click="toTop">
        <div class="toTopIcon" />
      </div>
    </transition>
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import RequestForm from "./components/requestForm.vue";
import mobileNavigationDrawer from "./components/mobileNavigationDrawer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    RequestForm,
    mobileNavigationDrawer,
  },
  data: () => ({
    isShowRequestForm: false,
    top: false,
    showNavigationDrawer: false,
    headerItems: [
      {
        id: 1,
        title: "About us",
        href: "#about_us",
      },
      {
        id: 2,
        title: "products AND SERVICES",
        href: "#product_and_services",
      },
      {
        id: 3,
        title: "Contacts",
        href: "#contacts",
      },
    ],
    productId: "",
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.top = top > 500;
      console.log("top", top)
    },
    showRequestForm(id) {
      this.$vuetify.goTo(0, 0);
      this.productId = id;
      this.isShowRequestForm = true;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
#app {
  font-family: "Barlow";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
}
.toTop {
  background: #f6f7f8;
  border: 1.5px solid rgba(30, 67, 237, 0.1);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  bottom: 70px;
  right: 28px;
  z-index: 110;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.toTop:hover {
  background: #1e43ed;
  border: 1.5px solid #1e43ed;
}
.toTop:hover .toTopIcon {
  background-color: #fbfbfb;
}
.toTopIcon {
  width: 26px;
  height: 26px;
  background-color: #1e43ed99;
  -webkit-mask: url("@/assets/img/toTopIcon.svg") no-repeat center;
  mask: url("@/assets/img/toTopIcon.svg") no-repeat center;
}
</style>
